import { Link } from "react-router-dom";
import classes from "./Footer.module.scss";

interface IFooter {
  style?: string;
  addClass?: string;
  banner?: boolean;
}
export default function Footer({ style, addClass, banner }: IFooter) {
  return (
    <footer
      className={[
        classes.Footer,
        classes[style ?? "stdStyle"],
        classes[addClass ?? "stdClass"],
      ].join(" ")}
    >
      {banner !== false ? (
        <div className={classes.insideTw}>
          <div className={[classes.fullSec, classes.ctaBanner].join(" ")}>
            <div className={classes.text}>
              <p className={classes.hd}>Need a venue for an upcoming event?</p>
              <p className={classes.sub}>Book our incredible event space!</p>
            </div>

            <div className={classes.btnWrap}>
              <Link to="/events" className={[classes.scLinkRev].join(" ")}>Learn More</Link>
            </div>
          </div>
        </div>
      ) : null}

      <div className={classes.med3Def}>
        <p className={[classes.copy].join(" ")}>
          © {new Date().getFullYear()} Alabama Gulf Coast Sports Center. All rights reserved. <br/>Design by{" "}
          <a
            href="http://hsdesignhouse.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            High Seas Design House.
          </a>
        </p>
      </div>
    </footer>
  );
}
