import classes from './Shared.module.scss'

interface IPhone {
  where?: string
  addText?: string
  callText?: string
  showIcon?: boolean
}

export default function Phone(props: IPhone) {
  return (
    <a
      className={[classes.callLink, classes[props.where ?? 'classic']].join(' ')}
      href="tel: +12516093191"
      title="Click to call Alabama Gulf Coast Sports Center"
    >
      {props.addText ? 
        <>
          [props.addText] 
          <br className={classes.cbr}/>
        </>
        : null
      }
      {props.callText ? [props.callText] : '251.609.3191'}
    </a>
  )
}