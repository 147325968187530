import classes from './Social.module.scss'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'

interface ISocial {
  where?: string
}

export default function Social(props: ISocial) {
  return (
    <ul className={[classes.Social, classes[props.where ?? 'classic']].join(' ')}>
      <li><a href="https://www.facebook.com/profile.php?id=100077660023641" target="_blank" rel="noopener noreferrer" title="Click to visit AGC Sports Center's Facebook">
        <FacebookIcon />
      </a></li>

      <li><a href="https://www.instagram.com/agc.sportscenter" target="_blank" rel="noopener noreferrer" title="Click to visit AGC Sports Center's Instagram">
        <InstagramIcon />
      </a></li>
    </ul>
  )
}
