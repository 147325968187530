import classes from "./CatPageIntro.module.scss";

interface ICatPageIntro {
  bgText: any;
  title: any;
  subtitle?: string;
  text?: string;
  addClass?: string;
  where?: string;
  wrap?: string;
  longName?: boolean;
}

export default function CatPageIntro({
  bgText,
  title,
  addClass,
  where,
  wrap,
  subtitle,
  text,
  longName,
}: ICatPageIntro) {
  return (
    <div
      className={[
        classes.fullSec,
        classes.pageIntro,
        classes[where ?? "stdWhere"],
        classes[addClass ?? "stdClass"],
      ].join(" ")}
    >
      <div className={classes[wrap ?? "inside"]}>
        <div className={[classes.fullSec, classes.content].join(" ")}>
          <div className={classes.textbox}>
            {subtitle ? <p className={classes.subtitle}>{title}</p> : null}
            <h1
              className={[
                classes.bigHd,
                classes[longName ? "long" : "stdLength"],
              ].join(" ")}
            >
              {title}
            </h1>
            {text ? <div className={classes.text}>{text}</div> : null}
          </div>
          <h2 className={classes.list}>{bgText}</h2>
        </div>
      </div>
    </div>
  );
}
