import { Helmet } from 'react-helmet'
// import { Link } from "react-router-dom";
import classes from './Property.module.scss'
import CatPageIntro from "../../components/CatPageIntro/CatPageIntro"
import bumz from "../../assets/images/logoBumz.png"
import mvp from "../../assets/images/logoMvp.png"
import pivc from "../../assets/images/logoPivc.png"

export default function Property() {
  const bgText = (
    <>
      Sports &amp; Fitness &middot; Coffee Shop &amp; Restaurant &middot; Shopping &middot; Event Venue
    </>
  );
  const h1 = <>Discover Fitness, Flavors, <br/>and Fun All On AGCSC Property</>

  return (
    <div className={[classes.pageStyles, classes.Info].join(' ')}>
      <Helmet>
        <title>Sports & Shopping Complex in Gulf Shores, Alabama</title>
        <meta name="description" content="With a coffee shop, retail spaces, and the MVP Fitness Training Center, our facility provides much more than just indoor and outdoor recreational areas." />
      </Helmet>

      <CatPageIntro title={h1} bgText={bgText} where="info" />
        
      <div className={[classes.fullSec, classes.introText].join(" ")}>
        <div className={classes.insideTw}>
          <p>Alabama Gulf Coast Sports Center is more than just a state-of-the-art athletic facility. In addition to our indoor and outdoor recreational areas and event venue, our 
            complex also features a coffee shop/restaurant, retail spaces, and the MVP Fitness Training Center.
          </p>
        </div>
      </div>

      <div className={[classes.bodyBlockAlt, classes.bg, classes.focLeft, classes.bumz].join(" ")}>
        <div className={classes.insideTw}>
          <div className={[classes.fullSec, classes.colWrap].join(" ")}>
            <div className={[classes.col, classes.col1, classes.big].join(" ")}>
              <img src={bumz} alt="Beach Bumz logo, coffee shop and cafe opening in Gulf Shores, AL" className={classes.blkLogo} />
            </div>

            <div className={[classes.col, classes.col2].join(" ")}>
              <p>Indulge yourself at <b>Beach Bumz</b>, our cozy little coffee shop and cafe. Refuel, recharge, or just relax while you enjoy the aroma of freshly brewed coffee, and the tempting array of treats.</p>
            </div>

            <div className={[classes.col, classes.col3, classes.btnCol].join(" ")}>
              <a href="https://beachbumzcafe.com/" target="_blank" rel="noreferrer" className={[classes.scLink].join(' ')}>Visit Website</a>
            </div>
          </div>
        </div>
      </div>

      <div className={[classes.bodyBlockAlt, classes.white, classes.focRight, classes.mvp].join(" ")}>
        <div className={classes.insideTw}>
          <div className={[classes.fullSec, classes.colWrap].join(" ")}>
            <div className={[classes.col, classes.col1, classes.big].join(" ")}>
              <img src={mvp} alt="MVP Fitness logo, tailored fitness solutions in Gulf Shores" className={classes.blkLogo} />
            </div>

            <div className={[classes.col, classes.col2].join(" ")}>
              <p>The MVP Fitness Program is made up of 10 comprehensive workouts, designed to optimize an athlete's performance both mentally and physically. 
                Their goal is to give athletes the ability to take their sport or discipline to the highest possible level. 
              </p>
            </div>

            <div className={[classes.col, classes.col3, classes.btnCol].join(" ")}>
              <a href="https://mvpfitness.com/" target="_blank" rel="noreferrer" className={[classes.scLink].join(' ')}>Visit Website</a>
            </div>
          </div>
        </div>
      </div>

      <div className={[classes.bodyBlockAlt, classes.bg, classes.focLeft, classes.pivc].join(" ")}>
        <div className={classes.insideTw}>
          <div className={[classes.fullSec, classes.colWrap].join(" ")}>
            <div className={[classes.col, classes.col1, classes.big].join(" ")}>
              <img src={pivc} alt="Pleasure Island Storm Volleyball Club logo" className={classes.blkLogo} />
            </div>

            <div className={[classes.col, classes.col2].join(" ")}>
              <p>The Pleasure Island Storm Volleyball Club is a USAV Junior Olympic Program for girls 10-18 that provides an environment for individuals to develop a 
                healthy competitive spirit, practice leadership skills, and learn teamwork. 
              </p>
            </div>

            <div className={[classes.col, classes.col3, classes.btnCol].join(" ")}>
              <a href="https://pivc.org/" target="_blank" rel="noreferrer" className={[classes.scLink].join(' ')}>Visit Website</a>
            </div>
          </div>
        </div>
      </div>

      <div className={[classes.fullSec, classes.simpleText].join(" ")}>
        <div className={classes.medDef}>
          <p>Check back for updates as we get closer to our opening date!</p>
        </div>
      </div>
    </div>
  )
}
