import { Helmet } from 'react-helmet'
import classes from './Contact.module.scss'
import Form from '../../components/Form/Form'
import Phone from '../../components/Shared/Phone'
import Email from '../../components/Shared/Email'
import {Address} from '../../components/Shared/Address'
import Social from '../../components/Social/Social'

export default function Contact() {
  return (
    <div className={[classes.pageStyles, classes.Contact].join(' ')}>
      <Helmet>
        <title>Visit, Train, or Host an Event at Alabama Gulf Coast Sports Center</title>
        <meta name="description" content="Contact Alabama Gulf Coast Sports Center for more information about our facility, athletic programs, and event venue." />
      </Helmet>

      <div className={[classes.fullSec, classes.v2].join(' ')}>
        <div className={classes.medDef}>
          <div className={[classes.mainRow, classes.flexRow].join(' ')}>
            <div className={[classes.info].join(' ')}>
              <h1>Get in Touch</h1>
              <div className={[classes.fullSec, classes.item, classes.cmail].join(' ')}>
                <Phone />
                <span className={classes.dot}> &middot; </span>
                <Email />
              </div>
              <div className={[classes.fullSec, classes.item, classes.loc].join(' ')}>
                <Address showBtn={true} btnStyle='textLink' btnText="Get Directions" altStyle={true} addClass="conPage" />
              </div>
              <div className={[classes.fullSec, classes.item, classes.soc].join(' ')}><Social /></div>
            </div>

            <div className={[classes.formWrap].join(' ')}>
              <Form inputBg='white' />
            </div>
          </div>
        </div>

        <div className={classes.rotext}>Coming <b>December</b> 2024</div>
      </div>
    </div>
  )
}
