import { Helmet } from "react-helmet"
import classes from "./Athletics.module.scss"
import CatPageIntro from "../../components/CatPageIntro/CatPageIntro"
import BodyBlock from "../../components/BodyBlock/BodyBlock"

export default function Athletics() {
  const bgText = (
    <>
      Volleyball &middot; Basketball &middot; Pickleball
      <br />
      Sporting Events &middot; Private Training &middot; Camps &middot; Indoor
      &amp; Outdoor Activities
    </>
  );

  const h1 = <>We Specialize in Youth Sports,<br/> Fitness, &amp; Personal Training</>

  const bodyTitle1 = (
    <>
      Sports <span className={classes.thin}>&amp; Activities</span>
    </>
  );

  const text11 = (
    <>
      <p>Whether you're a young athlete looking to excel in sports, or an individual seeking tailored fitness solutions, AGCSC is 
        the ultimate destination to <b className={classes.gold}>elevate your game, achieve your fitness goals</b>, and 
        embrace a <b className={classes.gold}>healthier lifestyle</b>.
      </p>
      <p>
        Our state-of-the-art facility provides quality ammenities for{" "}
        <b className={classes.gold}>volleyball, basketball</b>, and{" "}
        <b className={classes.gold}>pickleball</b>.
      </p>
    </>
  );
  const text21 = (
    <>
    <p>
      Our indoor recreational area can be converted into 12 pickleball courts,
      4 basketball courts, and/or 4 volleyball courts.
    </p> 
    <p>
      This extremely dynamic, 28,000 ft² space is also{" "}
      <b className={classes.gold}>available for rent</b>, and is the perfect
      venue for <b className={classes.gold}>organized sporting events</b>. Let
      us host your next <b className={classes.gold}>karate</b> tournament,{" "}
      <b className={classes.gold}>gymnastics</b> meet, or{" "}
      <b className={classes.gold}>dance</b> competition!
    </p>
    </>
  );


  const text31 = (
    <p className={classes.bold}>
      Our outdoor space offers{" "}
      <span className={classes.gold}>7 beach volleyball courts</span> with
      lights.
    </p>
  );

  const bodyTitle2 = (
    <>
      Leagues &amp; <span className={classes.thin}>Programs</span>
    </>
  );
  const text12 = (
    <p className={classes.bold}>
      Alabama Gulf Coast Sports Center offers{" "}
      <span className={classes.teal}>
        volleyball and basketball training programs.
      </span>
    </p>
  );
  const text22 = (
    <p>
      We also offer <b>beach and indoor volleyball leagues for adults</b>, as
      well as multiple training camps and competitions.
    </p>
  );
  const text32 = (
    <p>
      The primary host for volleyball is the travel program,{" "}
      <b>Pleasure Island Storm Volleyball Club.</b>
    </p>
  );

  return (
    <div className={[classes.pageStyles, classes.Athletics].join(" ")}>
      <Helmet>
        <title>Volleyball, Basketball, Pickleball & Fitness Center in Gulf Shores</title>
        <meta name="description" content="AGCSC promotes fitness and health along the Alabama Gulf Coast with the help of our volleyball, basketball, and pickleball amenities, and personal training options." />
      </Helmet>

      <CatPageIntro title={h1} bgText={bgText} where="athletics" />

      {/* *** pickleball will be 5-6 days a week ? */}
      <BodyBlock
        lay="focRight"
        color="teal"
        addClass="ath1"
        title={bodyTitle1}
        emph="circle"
        wrap="medDef"
        text1={text11}
        text2={text21}
        text3={text31}
      />

      <BodyBlock
        lay="focLeft"
        color="bg"
        addClass="ath2"
        emph="circle"
        title={bodyTitle2}
        wrap="medDef"
        text1={text12}
        text2={text22}
        text3={text32}
      />
    </div>
  );
}
