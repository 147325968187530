import classes from './Shared.module.scss'
// import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection'
// import DirectionsIcon from '@mui/icons-material/Directions'
// import FmdGoodIcon from '@mui/icons-material/FmdGood'
// import LocationOnIcon from '@mui/icons-material/LocationOn'


interface IDirections {
  styled?: 'scLink' | 'scLinkRev' | 'scLinkSm' | 'textLink'
  addClass?: string
  btnText?: string
}
interface IAddress {
  altStyle?: boolean
  addClass?: string
  address?: string
  addText?: string
  showBtn?: boolean
  btnText?: string
  btnStyle?: 'scLink' | 'scLinkRev' | 'scLinkSm' | 'textLink'
  btnClass?: string
}

export function Directions({ styled, addClass, btnText }: IDirections) {
  return (
    <a
      href="https://www.google.com/maps/dir/Orange+Beach,+Alabama/Gulf+Shores+Sportsplex,+19025+Oak+Rd+W,+Gulf+Shores,+AL+36542/@30.2889922,-87.7228589,12z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x889a0653ba3f0f09:0xea3bcac5eb6a8dbb!2m2!1d-87.5867598!2d30.2697033!1m5!1m1!1s0x889a1aacc5555555:0xfde98a43e442070e!2m2!1d-87.6985825!2d30.3159001?entry=ttu"
      target="_blank"
      rel="noreferrer"
      className={[classes[styled ?? 'scLinkSm'], classes[addClass ?? "std"]].join(' ')}
      type="button"
      title="Click for directions to Alabama Gulf Coast Sports Center"
    >
      {btnText ? [btnText] : "Directions"}
    </a>
  )
}

export function Address({
  altStyle,
  addClass,
  address,
  addText,
  showBtn,
  btnStyle,
  btnClass,
  btnText,
  }: IAddress) {
  
  let classy = 'addrP'
  if (showBtn && btnStyle !== 'textLink'){
    classy = 'withLink'
  } else if (showBtn && btnStyle === 'textLink'){
    classy = 'nospace'
  }

  return (
    <div className={[classes.addr, classes[addClass ?? 'classic']].join(' ')}>
      {addText ? 
        <>
          [addText]
          <br className={classes.cbr}/>
        </>
        : null
      }
      {address ? address 
        : altStyle ?
            <p className={classes[classy]}>
              19051 Oak Rd West <span className={[classes.dot, classes.dot1].join(' ')}>&middot;</span><br className={classes.noshow} /> Unit 123
              <span className={[classes.dot, classes.dot2].join(' ')}> &middot;</span><br className={classes.noshow2} /> Gulf Shores, AL 36542
            </p>
          : <p className={classes[classy]}>19051 Oak Rd West <br className={classes.br1} /> Unit 123<br className={classes.br2} /> Gulf Shores, AL 36542</p>
      }


      {showBtn &&
      <div className={classes.clearfixBtn}>
        <Directions styled={btnStyle ?? 'scLinkSm'} addClass={btnClass ?? 'std'} btnText={btnText ?? undefined} />
      </div>
      }
    </div>
  )
}