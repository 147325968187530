import { Helmet } from "react-helmet";
import classes from "./Events.module.scss";
import CatPageIntro from "../../components/CatPageIntro/CatPageIntro";
import BodyBlock from "../../components/BodyBlock/BodyBlock";
import Form from "../../components/Form/Form";

export default function Events() {
  const bgText = (
    <>
      Sporting Events &middot; Conferences &middot; Community Gatherings
      &middot; Graduation Ceremonies &middot; Trade Shows
    </>
  );
  const introTitle = (
    <>
      Let Us Help Host<br /> Your Next Event
    </>
  );

  const bodyTitle = (
    <>
      Customizable <span className={classes.thin}>Event Space</span>
    </>
  );
  const text1 = (
    <p className={[classes.lg, classes.bold].join(" ")}>
      Alabama Gulf Coast Sports Center features a versatile{" "}
      <span className={classes.gold}>28,000 ft² event space</span> that is
      available for rent!
    </p>
  );
  const text2 = (
    <>
      <p>
        This dynamic space is perfect for sporting events!{" "}
        <b className={classes.gold}>Volleyball tournaments</b>, <b className={classes.gold}>karate meets</b>, <b className={classes.gold}>dance competitions</b>,
        you name it! We can accommodate whatever you are planning.
      </p>
      <p>
        This sporting space can also be converted into{" "}
        <b className={classes.gold}>12 pickleball</b> courts,{" "}
        <b className={classes.gold}>4 basketball</b> courts and/or{" "}
        <b className={classes.gold}>4 volleyball</b> courts.
      </p>
    </>
  );
  const text3 = (
    <>
      <p>
        With ample room for creativity and customization, our venue is great for
        everything from <b className={classes.gold}>conferences</b> and{" "}
        <b className={classes.gold}>trade shows</b> to{" "}
        <b className={classes.gold}>receptions</b> and{" "}
        <b className={classes.gold}>celebrations</b>. Whether you're hosting a{" "}
        <b className={classes.gold}>small community gathering</b>, or a{" "}
        <b className={classes.gold}>big graduation ceremony</b>, we can help
        make your event an unforgettable success!
      </p>
    </>
  );

  return (
    <div className={[classes.pageStyles, classes.Events].join(" ")}>
      <Helmet>
        <title>Spacious Indoor Event Venue in Gulf Shores, Alabama</title>
        <meta name="description" content="Our versatile event space is perfect for hosting anything from sporting events to conferences and trade shows, small gatherings or large receptions." />
      </Helmet>

      <CatPageIntro
        title={introTitle}
        bgText={bgText}
        where="events"
        longName={true}
      />

      <BodyBlock
        lay="focLeft"
        color="teal"
        addClass="stack12"
        addClass2="ev1"
        emph="circle"
        title={bodyTitle}
        text1={text1}
        text2={text2}
        text3={text3}
        focLg={true}
      />

      <div className={[classes.fullSec, classes.bookForm].join(" ")}>
        <div className={[classes.insideNrw, classes.intro].join(" ")}>
          <h3>Ready to book our venue?</h3>
          <p>
            If you are interested in booking our venue, or if you just want more
            information about holding your event with us, fill out the form
            below. We will be in touch soon!
          </p>
        </div>

        <div className={[classes.med4Def, classes.formOuter].join(" ")}>
          <div className={[classes.fullSec, classes.formWrap].join(" ")}>
            <Form
              label="Details"
              phText="Give us a few details about your event. Occasion, desired date, # of guests, etc..."
            />
          </div>
        </div>
      </div>
    </div>
  );
}
