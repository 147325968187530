import classes from './Form.module.scss'

interface IForm {
  label?: string
  phText?: any
  inputBg?: 'white' | 'fa' | undefined
  addClass?: string
}
export default function Form({label, phText, addClass, inputBg}: IForm) {
  return (
    <div className={[classes.Form, classes[addClass ?? 'stdForm'], classes[inputBg ?? 'fa']].join(' ')}>
      <form
        action="https://formspree.io/f/xjvnwogo"
        method="POST"
      >
        <div className={[classes.formGroup, classes.name].join(' ')}>
          <label>*Name</label><br/>
          <input type="name" name="name" />
        </div>

        <div className={[classes.formGroup, classes.combo].join(' ')}>
          <div className={[classes.formGroupSub, classes.email].join(' ')}>
            <label>*Email</label><br/>
            <input type="email" name="email" />
          </div>

          <div className={[classes.formGroupSub, classes.phone].join(' ')}>
            <label>*Phone</label><br/>
            <input type="phone" name="phone" />
          </div>
        </div>

        <div className={[classes.formGroup, classes.message].join(' ')}>
          <label>{label ?? 'Message'}</label><br/>
          <textarea name="message" placeholder={phText ? phText : null}></textarea>
        </div>

        <div className={[classes.formGroup, classes.send].join(' ')}>
          <button type="submit" className={classes.scLink}>Submit</button>
        </div>
      </form>
    </div>
  )
}