import { Link } from 'react-router-dom'
import classes from './Header.module.scss'
// const dropIcon = (<span className={classes.parentIcon} data-uk-icon="icon: chevron-down;"></span>)

interface INav {
  navClass?: string
  addClass?: string
}

export default function navItems(props: INav){
  return (
    <ul className={[classes[props.navClass ?? 'navGroup'], classes[props.addClass ?? 'addClass']].join(' ')}>
      <li className={[classes.navItem, classes.homeLink].join(' ')}><Link to="/">Home</Link></li>
      <li className={[classes.navItem].join(' ')}><Link to="/athletics">Athletics</Link></li>
      {/* Facility  Property  Information Discover Explore */}
      <li className={[classes.navItem].join(' ')}><Link to="/events">Events</Link></li>
      <li className={[classes.navItem].join(' ')}><Link to="/discover">Information</Link></li>
      <li className={[classes.navItem, classes.mid].join(' ')}><Link to="/contact">Contact &amp; Location</Link></li>
    </ul>
  )
}