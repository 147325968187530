import * as React from 'react'
import classes from './Header.module.scss'
import styles from '../Footer/Footer.module.scss'
import NavItems from './NavItems'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import Social from '../Social/Social'
import Email from '../Shared/Email'
import Phone from '../Shared/Phone'
import { Address } from '../Shared/Address'


export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: 'left', open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' && (
          (event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift'
        )
      ) {
        return;
      }

      setState({ ...state, left: open });
    }

  const navList = (anchor: 'left') => (
    <div
      className={[classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer('left', false)}
      onKeyDown={toggleDrawer('left', false)}
    >
      <div className={classes.closeIcon}><CloseIcon /></div>
      <NavItems navClass="SideNav" />

      <div className={[classes.fullSec, classes.drContact].join(' ')}>
        <div className={[classes.dcItem, classes.phone].join(' ')}><Phone where="drawer" /></div>
        <div className={[classes.dcItem, classes.email].join(' ')}><Email where="drawer" /></div>
        <div className={[classes.dcItem, classes.addr].join(' ')}><Address showBtn={true} btnStyle="scLinkRev" btnClass="scLinkSm" /></div>
        <div className={[classes.dcItem, classes.social].join(' ')}><Social where="drawer" /></div>
      </div>
    </div>
  )

  return (
    <div className={[classes.NavDrawer, styles.ftDrawer].join(' ')}>
      <Button onClick={toggleDrawer('left', true)} className={classes.menuBtn}>
        <MenuIcon />
      </Button>

      <SwipeableDrawer
        anchor='left'
        open={state['left']}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
        className={classes.cNavDrawer}
      >
        {navList('left')}
      </SwipeableDrawer>
    </div>
  );
}