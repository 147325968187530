import {Component} from 'react'
import { Routes, Route } from 'react-router-dom'
import classes from './App.module.scss'
import withRouter from './components/Functionals/WithRouter'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import { BackToTop } from './components/Smooth/BackTop'
import Home from './Pages/Home/Home'
import Athletics from './Pages/Athletics/Athletics'
import Events from './Pages/Events/Events'
import Property from './Pages/Property/Property'
import Contact from './Pages/Contact/Contact'

class App extends Component {
  render() {
    let routes = (
      <Routes>
        {/* <Route exact={true} path="/" element={<Home />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/athletics" element={<Athletics />} />
        <Route path="/events" element={<Events />} />
        <Route path="/discover" element={<Property />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    )

    const pathName = window.location.pathname.replace('-','');
    let wrapClass = pathName.replace('/', '');
    if (wrapClass === ''){
      wrapClass = "home"
    }
    
    const HeadStyle = () => {
      if (window.location.pathname === '/contact') {
        return (<Header navbar="alt" />);
      }
      return (<Header />);
    }
    const SneakyHeader = withRouter(HeadStyle);

    const FootStyle = () => {
      if (window.location.pathname === '/events') {
        return (<Footer banner={false} />);
      }
      return (<Footer />);
    }
    const SneakyFooter = withRouter(FootStyle);
    
    return (
      <div id="agcsc-top" className={[classes.scWrap, classes.fullSec, classes[wrapClass]].join(' ')}>
        <SneakyHeader />
        <main className={classes.mainWrap}>
          {routes}
        </main>
        <SneakyFooter />

        <BackToTop />
      </div>
    )
  }
}
export default App
