import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import classes from "./Home.module.scss";
import front from "../../assets/images/renderFront.png";
import side from "../../assets/images/renderSide.png";
import solo from "../../assets/images/renderSolo.png";

export default function Home() {
  const intro = (
    <>
      <h2 className={[classes.nameHd, classes.cat].join(" ")}>Alabama Gulf Coast Sports Center</h2>
      <h1 className={classes.mainHd}>Sports &amp; Fitness Complex &amp; <br/> Event Center in Gulf Shores</h1>
      <h3 className={[classes.soon].join(" ")}>Coming December 2024</h3>
    </>
  )

  const athBtn = (<Link to="/athletics" title="Click for more info on AGCSC athletic programs" className={classes.scLink}>Learn More</Link>)
  const eventBtns = (
    <>
      <Link to="/events" title="Click to learn about our event venue, available for rent" className={classes.scLink}>Learn More</Link>
      <Link to="/contact" title="Click for contact info to book our event venue" className={classes.scLink}>Contact Us</Link>
    </>
  )
  const infoBtn = (<Link to="/discover" title="Click to discover all that our complex has to offer" className={classes.scLink}>Learn More</Link>)


  return (
    <div className={[classes.pageStyles, classes.Home].join(" ")}>
      <Helmet>
        <title>Multi-Sports Facility and Event Venue in Baldwin County | Alabama Gulf Coast Sports Center</title>
        <meta name="description" content="Alabama Gulf Coast Sports Center specializes in fitness and youth sports, with quality amenities for volleyball, basketball, and pickleball, as well as an event venue." />
      </Helmet>

      <div className={[classes.fullSec, classes.griddy].join(" ")}>
        <div className={classes.insideXl}>
          <div className={[classes.grImage, classes.i1].join(" ")}>
            <div className={classes.image}><img src={front} alt="AGCSC building rendering" /></div>
            <div className={classes.grText}>
              {intro}
            </div>
          </div>

          <div className={[classes.grImage, classes.i2].join(" ")}>
            <img src={solo} alt="AGCSC aerial property rendering" />
          </div>
          <div className={[classes.grImage, classes.i3].join(" ")}>
            <img src={side} alt="AGCSC outdoor space rendering" />
          </div>
          <div className={classes.mobileText}>
            {intro}
          </div>
        </div>
      </div>

      <div className={[classes.bodyBlock, classes.bg, classes.focLeft, classes.homeAth].join(" ")}>
        <div className={classes.medDef}>
          <div className={[classes.fullSec, classes.colWrap].join(" ")}>
            <div className={[classes.col, classes.col1, classes.big].join(" ")}>
              <p>Athl<br/><span className={classes.less}>etics</span></p>
            </div>

            <div className={[classes.col, classes.col2].join(" ")}>
              <p>
                Alabama Gulf Coast Sports Center specializes in <b>youth sports,
                fitness, and personal training.</b> Our state-of-the-art facility provides
                top-notch amenities for <b>basketball, volleyball, and pickleball.</b> It
                is also home to the <b>MVP Fitness</b> training facility.
              </p>
              <div className={classes.btnRow}>{athBtn}</div>
            </div>

            <div className={[classes.col, classes.col3, classes.btnCol].join(" ")}>
              {athBtn}
            </div>
          </div>
        </div>
      </div>
      
      <div className={[classes.bodyBlock, classes.teal, classes.focRight, classes.homeEv].join(" ")}>
        <div className={classes.medDef}>
          <div className={[classes.fullSec, classes.colWrap].join(" ")}>
            <div className={[classes.col, classes.col1, classes.big].join(" ")}>
              <p>Eve<br/>nts</p>
            </div>

            <div className={[classes.col, classes.col2].join(" ")}>
              <p>
                Our facility features a <b>28,000 ft² event space available for rent.</b> Whether you're planning a dynamic <b>sporting event</b>, a 
                memorable <b>graduation ceremony</b>, a professional <b>conference</b>, or a vibrant <b>community gathering,</b> our venue is the perfect canvas for your next function. Contact 
                us today to reserve your date!
              </p>
              <div className={classes.btnRow}>
                {eventBtns}
              </div>
            </div>

            <div className={[classes.col, classes.col3, classes.btnCol].join(" ")}>
              {eventBtns}
            </div>
          </div>
        </div>
      </div>

      <div className={[classes.bodyBlock, classes.bg, classes.focLeft, classes.homeProp].join(" ")}>
        <div className={classes.medDef}>
          <div className={[classes.fullSec, classes.colWrap].join(" ")}>
            <div className={[classes.col, classes.col1, classes.big].join(" ")}>
              <p>Prop<br/>erty</p>
            </div>

            <div className={[classes.col, classes.col2].join(" ")}>
              <p>
                Alabama Gulf Coast Sports Center is more than just a state-of-the-art athletic facility. In addition to our indoor and outdoor 
                recreational areas and event venue, our complex also features a coffee shop/restaurant, retail units, and the MVP Fitness Training Center.
              </p>
              <div className={classes.btnRow}>{infoBtn}</div>
            </div>

            <div className={[classes.col, classes.col3, classes.btnCol].join(" ")}>
              {infoBtn}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
