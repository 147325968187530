import classes from "./BodyBlock.module.scss";

interface ICatPageIntro {
  color: "bg" | "teal" | "navy" | "white" | "gold";
  lay: "focLeft" | "focRight";
  addClass?: string;
  addClass2?: string;
  topWrap?: string;
  wrap?: string;
  title?: any;
  subtitle?: any;
  text1?: any;
  text2?: any;
  text3?: any;
  focLg?: boolean
  emph?: 'border' | 'circle' | 'none'
}

export default function BodyBlock({
  color,
  lay,
  addClass,
  addClass2,
  topWrap,
  wrap,
  title,
  subtitle,
  text1,
  text2,
  text3,
  focLg,
  emph,
}: ICatPageIntro) {
  
  let dec = "border"
  if (emph && emph !== "border"){
    dec = emph
  }

  return (
    <div
      className={[
        classes.bodyBlock,
        classes[color],
        classes[lay],
        classes[addClass ?? "stdClass"],
        classes[addClass2 ?? "stdClass2"],
        classes[dec]
      ].join(" ")}
    >
      <div className={[classes.fullSec, classes.topRow].join(" ")}>
        <div
          className={[classes.trWrap, classes[topWrap ?? "medDef"]].join(" ")}
        >
          {title ? <h3><span className={classes.h3wrap}>{title}</span></h3> : null}
          {subtitle ? <h4>{subtitle}</h4> : null}
        </div>
      </div>

      <div
        className={[classes.colOuter, classes[wrap ?? "insideTw"]].join(" ")}
      >
        <div className={[classes.fullSec, classes.colWrap].join(" ")}>
          {text1 ? (
            <div className={[classes.col, classes.col1, classes[focLg === true && lay ==='focLeft' ? 'lg' : 'eh']].join(" ")}>{text1}</div>
          ) : null}
          {text2 ? (
            <div className={[classes.col, classes.col2].join(" ")}>{text2}</div>
          ) : null}
          {text3 ? (
            <div className={[classes.col, classes.col3, classes[focLg === true && lay ==='focRight' ? 'lg' : 'eh']].join(" ")}>{text3}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
